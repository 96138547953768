import React, { useEffect } from 'react';
import Button from '@atoms/Button';
import Swiper from '@atoms/Swiper';
import { TypoMBold, TypoXLBold, TypoXXS } from '@components/atoms/Typos';
import { Desktop, Mobile } from '@components/Layout';
import AboutHafhPlan from '@components/molecules/AboutHafhPlan';
import AboutHafhSection from '@components/molecules/AboutHafhSection';
import Tabs from '@components/molecules/Tabs';
import TopSearchWindow from '@components/molecules/TopSearchWindow';
import { useAppSelector } from '@hooks/useAppSelector';
import { useIsCoinBackCampaignActive } from '@hooks/useCampaign';
import CampaignTopHeroSection from '@molecules/CampaignTopHeroSection';
import JpTopFaq from '@molecules/JpTopFaq';
import PropertySlider from '@molecules/PropertySlider';
import TravelStorySlider from '@molecules/TravelStorySlider';
import ConvertSection from '@organisms/ConvertSection';
import TestimonialSlider from '@organisms/TestimonialSlider';
import * as reservationCountRankingActions from '@redux/modules/reservationCountRanking';
import * as travelStoriesListsAction from '@redux/modules/travelStoriesLists';
import { COIN_BACK_CAMPAIGN_TOPIC_URL } from '@utils/campaign';
import { useActions } from '@utils/hooks';
import routes from '@utils/routes';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { SwiperSlide } from 'swiper/react';
import styles from './css';

const JpTopMain = () => {
  const { t } = useTranslation('jp-top');
  const router = useRouter();

  const { locale } = router;

  const { reservationCountRanking, travelStoriesLists } = useAppSelector(
    (state) => ({
      reservationCountRanking: state.reservationCountRanking,
      travelStoriesLists: state.travelStoriesLists,
    })
  );

  const { getReservationCountRanking, getTravelStoriesList } = useActions({
    ...reservationCountRankingActions,
    ...travelStoriesListsAction,
  });

  useEffect(() => {
    getReservationCountRanking();

    if (travelStoriesLists.popular.travel_stories.length === 0) {
      getTravelStoriesList('popular', 1, locale);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const isCoinBackCampaignActive = useIsCoinBackCampaignActive();

  return (
    <div>
      <CampaignTopHeroSection />
      {isCoinBackCampaignActive && (
        <Link href={COIN_BACK_CAMPAIGN_TOPIC_URL}>
          <Mobile>
            <Image
              alt="Wチャンスキャンペーン"
              height={375}
              src="/images/campaign/coin-back/hero-inviter-mobile.png?v=1"
              style={{
                height: 'auto',
                width: '100%',
              }}
              width={375}
            />
          </Mobile>
          <Desktop>
            <Image
              alt="Wチャンスキャンペーン"
              height={640}
              src="/images/campaign/coin-back/banner-in-top.png?v=1"
              style={{
                height: 'auto',
                width: '100%',
              }}
              width={1440}
            />
          </Desktop>
        </Link>
      )}
      <AboutHafhSection />
      <section className="section plan">
        <div className="section-inner">
          <div className="title">
            <TypoXLBold text="プランについて" />
            <div className="divider" />
          </div>
          <AboutHafhPlan />
        </div>
      </section>
      <ConvertSection />
      <section className="section">
        <div className="section-inner">
          {!!reservationCountRanking?.length && (
            <>
              <div className="title">
                <TypoXLBold text="HafHで人気のホテル" />
                <div className="divider" />
              </div>
              <PropertySlider
                getPropertiesByArea={getReservationCountRanking}
                isAreaTabs={true}
                name="reservation-count-ranking"
                properties={reservationCountRanking}
                sliderType="ranking"
                title={t('browse:reservationCount')}
              />
            </>
          )}
          <TravelStorySlider type="popular" />
        </div>
      </section>
      <section className="section">
        <div className="section-inner">
          <div className="title">
            <TypoXLBold text="それぞれの" />
            <Mobile>
              <br />
            </Mobile>
            <TypoXLBold text="HafH旅スタイル" />
            <div className="divider" />
          </div>
          <Desktop>
            <Tabs
              tabTitles={[
                'Case 01\nごほうび女子旅',
                'Case 02\n自由気ままに癒し旅',
              ]}
            >
              {[...Array(2)].map((_, index_) => (
                <div key={`swiper-0${index_ + 1}`} className="swiper-container">
                  <Swiper
                    name="style"
                    options={{
                      slidesPerView: 3,
                      spaceBetween: 4,
                    }}
                  >
                    {[...Array(3)].map((__, index__) => (
                      <SwiperSlide key={`slide-0${index_ + 1}_0${index__ + 1}`}>
                        <Image
                          alt={`slide-0${index_ + 1}_0${index__ + 1}`}
                          height={388}
                          src={`/images/lp/japan/style/Case0${index_ + 1}_${
                            index__ + 1
                          }.png?v=1`}
                          style={{
                            height: 'auto',
                            width: '100%',
                          }}
                          width={347}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              ))}
            </Tabs>
          </Desktop>
          <Mobile>
            <Tabs
              tabTitles={[
                'Case 01\nごほうび女子旅',
                'Case 02\n自由気ままに癒し旅',
              ]}
            >
              {[...Array(2)].map((_, index_) => (
                <div key={`swiper-0${index_ + 1}`} className="swiper-container">
                  <Swiper
                    name="style"
                    options={{
                      slidesPerView: 1.1,
                      spaceBetween: 4,
                    }}
                  >
                    {[...Array(3)].map((__, index__) => (
                      <SwiperSlide key={`slide-0${index_ + 1}_0${index__ + 1}`}>
                        <Image
                          alt={`slide-0${index_ + 1}_0${index__ + 1}`}
                          height={349}
                          src={`/images/lp/japan/style/Case0${index_ + 1}_${
                            index__ + 1
                          }.png?v=1`}
                          style={{
                            height: 'auto',
                            width: '100%',
                          }}
                          width={311}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              ))}
            </Tabs>
          </Mobile>
        </div>
      </section>
      <TopSearchWindow showTitle={true} />
      <section className="section testimonial">
        <div className="section-inner">
          <TestimonialSlider />
        </div>
        <div className="signup-campaign-button">
          <Button
            label="今すぐ新規登録して招待特典をゲット"
            link={routes.signup}
            size="x-large"
            width="responsive"
          />
        </div>
      </section>
      <section className="section">
        <div className="section-inner">
          <JpTopFaq />
        </div>
      </section>
      <section className="section">
        <div className="section-inner">
          <div className="title">
            <TypoMBold text="概要" />
          </div>
          <div>
            <TypoXXS
              text={
                '● 本キャンペーンは予告なく中止・延長する場合がございます。\n● 会員登録は、おひとり様1アカウントのみであり、複数の会員アカウントを保有できません。また自己の会員アカウントを第三者に譲渡・移転することはできません。\n● 利用規約違反、または不正行為と当社が判断した場合、HafHコイン進呈の対象外とし、さらにHafHの利用停止又は会員登録の抹消となる可能性があります。\n● 退会された場合、HafHコインは進呈いたしかねますので、予めご了承ください。\n● 本キャンペーンの対象は、日本国内のプランをご利用の方にのみ適用されます。国外のプランをご利用の場合、付与コイン数が異なります。'
              }
            />
          </div>
        </div>
      </section>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default JpTopMain;
